import { getPersistor } from '@rematch/persist'

const isAvailable = type => {
  try {
    const storage = window[type]
    const test = '__TEST__'
    storage.setItem(test, test)
    storage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

const isLocalStorageAvailable = isAvailable('localStorage')
let accessToken = null
let refreshToken = null

if (isLocalStorageAvailable) {
  accessToken = JSON.parse(localStorage.getItem('access_token'))
  refreshToken = JSON.parse(localStorage.getItem('refresh_token'))
}

export const get = key => {
  if (isLocalStorageAvailable) {
    return JSON.parse(localStorage.getItem(key))
  }
  return null
}

export const set = (key, data) => {
  if (isLocalStorageAvailable) {
    localStorage.setItem(key, JSON.stringify(data))
  }
}

export const getAccessTokenFromLocalStorage = () =>
  window.localStorage.getItem('access_token')
export const getAccessToken = () => accessToken
export const setAccessToken = token => {
  accessToken = token
  set('access_token', token)
}

export const getMobileAccessTokenFromLocalStorage = () =>
  window.localStorage.getItem('mobile_access_token')

export const getRefreshToken = () => refreshToken
export const setRefreshToken = token => {
  refreshToken = token
  set('refresh_token', token)
}

const IMPERSONATING_KEY = 'impersonating_user_id'

export const remove = () => {
  const persistor = getPersistor()
  if (persistor) {
    persistor.purge()
  }
  if (isLocalStorageAvailable) {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem(IMPERSONATING_KEY)
  }
}

const LOCALE_KEY = 'locale'
const PROMO_VARIATION_KEY = 'promo-banner-variation'

export const getLang = () => get(LOCALE_KEY)
export const setLang = data => set(LOCALE_KEY, data)

export const getImpersonatingUserId = () => get(IMPERSONATING_KEY)
export const setImpersonatingUserId = data => set(IMPERSONATING_KEY, data)

export const getPromoBannerVariation = () => get(PROMO_VARIATION_KEY)
export const setPromoBannerVariation = variation =>
  set(PROMO_VARIATION_KEY, variation)

const LAST_READ_MESSAGE_KEY = 'lastReadMessage'
export const getLastReadMessage = () =>
  window.localStorage.getItem(LAST_READ_MESSAGE_KEY)
export const setLastReadMessage = messageId => {
  set(LAST_READ_MESSAGE_KEY, messageId)
}
